
import { lazy, useEffect, useState } from "react";
import "./App.css";
import "./styles/index.css";

import { Typography, Box, Divider } from "@mui/material";
import { Container } from "@mui/system";

import { useEventListener } from "./useEventListener";

const GetTheLight = lazy(() => import("./get-the-light/GetTheLight"));
const FollowMeLinks = lazy(() => import("./components/FollowMeLinks"));
const Project = lazy(() => import("./components/Project"));

enum GameMode {
  Normal,
  GetTheLight,
}

function App() {
  const projects = (
    <div className="projects-container">
      <div className="projects">
        <Project
          name="London Fencing"
          link="https://london-fencing.bonner.is/"
        />
        <Project
          name="QuadTree in Rust WASM"
          link="https://moritonal.github.io/QuadTree-Example-Wasm/"
        />
        <Project
          name="Upcoming Movies"
          link="https://upcomingmovies.bonner.is"
        />
        <Project
          name="emoji-to-icon"
          link="https://github.com/moritonal/emoji-to-icon"
        />
        <Project
          name="Mermaid Live"
          link="https://moritonal.github.io/mermaid-live/"
        />
        <Project
          name="Untitled Boat Game"
          link="https://www.youtube.com/watch?v=DZX4m11yb3w"
        />
        <Project
          name="Node-RED USB-Watch"
          link="https://github.com/moritonal/node-red-contrib-usb-watch"
        />
        <Project
          name="Custom Local Domain"
          link="https://github.com/moritonal/custom-local-domain"
        />
        <Project
          name="X in Y"
          link="https://moritonal.github.io/XinYmins/"
        />
        <Project
          name="Coding With Tom"
          link="https://moritonal.github.io/CodingWithTom/"
        />
        <Project
          name="A twitch wedding"
          link="https://github.com/moritonal/a-twitch-wedding"
        />
        <Project
          name="Can I Call"
          link="https://canicall.info"
        />
        <Project
          name="Shady.is"
          link="https://moritonal.github.io/shady.is/"
        />
        <Project
          name="Zoon"
          link="https://www.youtube.com/channel/UCX_3hQoFac1JyO5DjHCC7cQ"
        />
        <Project
          name="Flocking"
          link="https://github.com/Moritonal/Flocking-Example"
        />
        <Project
          name="3D"
          link="https://github.com/Moritonal/DirectX11-Demo"
        />
        <Project
          name="Force"
          link="https://moritonal.github.io/force-particles/"
        />
        <Project
          name="Quad"
          link="https://github.com/Moritonal/QuadTree-Example"
        />
        <Project
          name="WebGL"
          link="https://moritonal.github.io/simple-web-gl/"
        />
        <Project
          name="Phunk"
          link="https://github.com/Moritonal/Phunk.NET"
        />
        <Project
          name="BdBuilder"
          link="https://www.nuget.org/packages/BdBuilder/"
        />
      </div>
    </div>
  );

  const [keyBuffer, setKeyBuffer] = useState<Array<KeyboardEvent>>([]);

  useEventListener("keydown", (e: KeyboardEvent) => {
    const newKeyBuffer = [e, ...keyBuffer];

    if (newKeyBuffer.length > 4) {
      newKeyBuffer.pop();
    }

    setKeyBuffer(newKeyBuffer);
  });

  const [mode, setGameMode] = useState<GameMode>(GameMode.Normal);

  useEffect(() => {
    function checkString(str: string) {
      let slicedBuffer = keyBuffer.slice(0, str.length);

      let val = slicedBuffer
        .map((i) => i.key)
        .join("")
        .toLowerCase();

      var t = val.split("").reverse().join("");

      return t === str;
    }

    if (checkString("tish")) {
      setGameMode(GameMode.GetTheLight);
    }
  }, [keyBuffer]);

  switch (mode) {
    case GameMode.Normal:
      return (
        <main>
          <Container maxWidth="lg">
            <Box sx={{ my: 4 }}>
              <Typography
                variant="h2"
                fontFamily={"Open Sans, sans-serif"}
                component="h1"
                gutterBottom
                align="center"
                color="textPrimary"
              >
                tom.bonner.is/coding
              </Typography>
              <Divider />
            </Box>

            {projects}

            <div>
              <FollowMeLinks
                twitter="https://twitter.com/moritonal"
                linkedIn="https://www.linkedin.com/in/moritonal/"
                github="https://github.com/moritonal"
                ghost="https://blog.bonner.is"
              />
            </div>
          </Container>
        </main>
      );
    case GameMode.GetTheLight:
      return <GetTheLight />;
  }
}

export default App;
